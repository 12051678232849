import { createRouter, createWebHistory } from 'vue-router'
import {i18nVue} from "laravel-vue-i18n";

const routes = [
    {
        name: 'home',
        path: '/',
        props:true,
        meta: {
            label: 'menu.home',
            header: true,
            roles: 'Super User, Activities Provider',
            permissions:'',
            display:true,
            breadcrumb: true,
            title: 'Home'
        },
        component: () => import('./views/home.vue')
    },
    {
        name: 'dashboard',
        path: '/dashboard',
        props:true,
        meta: {
            label: 'menu.dashboard',
            header: true,
            roles: 'Super User',
            permissions:'Manage reports',
            display:true,
            breadcrumb: true,
            title: 'Dashboard'
        },
        component: () => import('./views/dashboard.vue')
    },
    {
        name: 'auth.login',
        path: '/login',
        props: true,
        meta: {
            label: 'Login',
            display: false,
            roles: '',
            breadcrumb: false,
            title: 'Login'
        },
        component: () => import('./views/auth/login.vue')
    },
    {
        name: 'auth.register',
        path: '/register',
        props:true,
        meta: {
            label: 'Register',
            display: false,
            breadcrumb: false,
            title: 'Register'
        },
        component: () => import('./views/auth/register.vue')
    },
    {
        name: 'auth.logout',
        path: '/logout',
        props: true,
        meta: {
            label: 'Logout',
            display: false,
            roles: '',
            breadcrumb: false,
            title: 'Logout'
        },
        component: () => import('./views/auth/login.vue')
    },
    {
        name: 'auth.reset.password',
        path: '/reset-password/:email',
        component: () => import('./views/auth/resetPassword.vue'),
        meta: {
            display: false,
            roles: '',
            breadcrumb: false,
            title: 'Reset password'
        },
        props:true
    },
    {
        name: 'gift.card.redemption',
        path: '/gift-card-redemption',
        props: true,
        component: () => import('./views/admin/cards/cardRedemption.vue'),
        meta: {
            label: 'menu.gift_card_redemptions',
            header: true,
            roles: 'Activities Provider',
            permissions: 'Manage gift card redemptions',
            display: true,
            mobile:true,
            breadcrumb: true,
            title: 'Manage gift card redemptions'
        }
    },
    {
        name: 'activity.provider',
        path: '/activity-provider',
        props:true,
        meta: {
            label: 'menu.activity_provider',
            header: true,
            roles: 'Activities Provider',
            permissions: 'Manage provider',
            display:true,
            mobile:true,
            breadcrumb: false,
            title: 'Activity Provider'
        },
        children: [
            {
                name: 'activity.provider.dashboard',
                path: '/activity-provider/dashboard',
                props:true,
                component: () => import('./views/activityProvider/dashboard.vue'),
                meta: {
                    label: 'menu.dashboard',
                    roles: 'Activities Provider',
                    permissions: 'Manage provider',
                    display:true,
                    mobile:false,
                    breadcrumb: true,
                    title: 'Dashboard | Activity Provider'
                }
            },
            {
                name: 'activity.provider.filing.cabinet',
                path: '/activity-provider/my-details',
                props:false,
                component: () => import('./views/activityProvider/filingCabinet.vue'),
                meta: {
                    label: 'menu.my_details',
                    roles: 'Activities Provider',
                    permissions: 'Edit profile',
                    display:true,
                    mobile:false,
                    breadcrumb: true,
                    title: 'Profile | Activity Provider'
                }
            },
            {
                name: 'activity.provider.brand',
                path: '/activity-provider/brand',
                props:true,
                component: () => import('./views/admin/brands/brandsDetails.vue'),
                meta: {
                    label: 'menu.brand',
                    roles: 'Activities Provider',
                    display:true,
                    mobile:false,
                    breadcrumb: true,
                    title: 'Brand | Activity Provider'
                }
            },
            {
                name: 'activity.provider.locations',
                path: '/activity-provider/locations',
                props:true,
                component: () => import('./views/admin/locations/location-dashboard.vue'),
                meta: {
                    label: 'menu.locations',
                    roles: 'Activities Provider',
                    permissions: 'Manage locations',
                    display:true,
                    mobile:false,
                    breadcrumb: true,
                    title: 'Locations | Activity Provider'
                }
            },
            {
                name: 'activity.provider.classes',
                path: '/activity-provider/classes',
                props:true,
                component: () => import('./views/admin/classes/activitiesClassDashboard.vue'),
                meta: {
                    label: 'menu.classes',
                    roles: 'Activities Provider',
                    permissions: 'Manage classes',
                    display:true,
                    mobile:false,
                    breadcrumb: true,
                    title: 'Classes | Activity Provider'
                }
            },
            {
                name: 'activity.provider.enquiries',
                path: '/activity-provider/enquiries',
                props:true,
                component: () => import('./views/admin/enquiries/enquiriesDashboard.vue'),
                meta: {
                    label: 'menu.enquiries',
                    roles: 'Activities Provider',
                    permissions: 'Manage enquiries',
                    display:true,
                    mobile:true,
                    breadcrumb: true,
                    title: 'Enquiries | Activity Provider'
                }
            },
            {
                name: 'activity.provider.users',
                path: '/activity-provider/users',
                props:true,
                component: () => import('./views/admin/users/usersDashboard.vue'),
                meta: {
                    label: 'menu.users',
                    header: false,
                    roles: 'Activities Provider',
                    permissions: 'Manage users',
                    display:true,
                    mobile:false,
                    breadcrumb: true,
                    title: 'Users | Activity Provider'
                },
            },
            {
                name: 'activity.provider.billing',
                path: '/activity-provider/billing',
                props:false,
                component: () => import('./views/activityProvider/billing.vue'),
                meta: {
                    label: 'menu.billing',
                    roles: 'Activities Provider',
                    permissions: 'Manage billing',
                    display:true,
                    mobile:false,
                    breadcrumb: true,
                    title: 'Billing | Activity Provider'
                }
            },
            {
                name: 'activity.provider.faq',
                path: '/activity-provider/faq',
                props:false,
                component: () => import('./views/activityProvider/faq.vue'),
                meta: {
                    label: 'menu.help',
                    roles: 'Activities Provider',
                    permissions: 'Manage provider',
                    display:true,
                    mobile:false,
                    breadcrumb: true,
                    title: 'FAQ | Activity Provider'
                }
            },
        ]
    },
    {
        name: '/',
        path: '/',
        props: true,
        meta: {
            label: 'menu.activity_providers',
            header: true,
            roles: 'Super User',
            display: true,
            permissions: 'Manage provider',
            breadcrumb: false,
        },
        children : [
            {
                name: 'brands.dashboard',
                path: '/admin/brands',
                props:true,
                component: () => import('./views/admin/brands/dashboard.vue'),
                meta: {
                    label: 'menu.brands',
                    roles: 'Super User',
                    permissions: 'Manage brand',
                    display:true,
                    breadcrumb: true,
                    title: 'Brands | Admin'
                },
                children : [
                    {
                        name: 'brand.manage',
                        path: '/admin/brand/manage/:guid',
                        props:true,
                        component: () => import('./views/admin/brands/brandsDetails.vue'),
                        meta: {
                            label: 'menu.brand',
                            roles: 'Super User',
                            display: false,
                            breadcrumb: true,
                            title: 'Manage Brand | Brands | Admin'
                        }
                    }
                ]
            },
            {
                name: 'admin.providers',
                path: '/admin/providers',
                props: true,
                component: () => import('./views/admin/activityProviders/activitiesProviderDashboard.vue'),
                meta: {
                    label: 'menu.activity_providers',
                    roles: 'Super User',
                    permissions: 'Manage provider',
                    display:true,
                    breadcrumb: true,
                    title: 'Activity Providers | Admin'
                },
                children : [
                    {
                        name: 'provider.manage',
                        path: '/admin/provider/manage/:guid',
                        props:true,
                        component: () => import('./views/admin/activityProviders/activityProviderDetails.vue'),
                        meta: {
                            label: 'menu.activity_provider',
                            roles: 'Super User',
                            display: false,
                            breadcrumb: true,
                            title: 'Manage Activity Provider | Activity Provider | Admin'
                        }
                    },
                ],
            },
        ]
    },
    {
        name: 'customers',
        path: '/admin/customers',
        props:true,
        meta: {
            label: 'menu.customers',
            header: true,
            display:true,
            roles: 'Super User',
            breadcrumb: false,
            permissions: 'Manage customers',
            title: 'Customers | Admin'
        },
        children : [
            {
                name: 'purchasers.dashboard',
                path: '/admin/purchasers',
                props: true,
                component: () => import('./views/admin/purchasers/dashboard.vue'),
                meta: {
                    label: 'menu.purchasers',
                    roles: 'Super User',
                    permissions: 'Manage customers',
                    display: true,
                    breadcrumb: true,
                    title: 'Purchasers | Customers | Admin'
                },
                children: [
                    {
                        name: 'manage.purchaser',
                        path: '/admin/purchasers/manage-purchaser/:guid',
                        props: true,
                        component: () => import('./views/admin/purchasers/purchaserDetails.vue'),
                        meta: {
                            label: 'menu.purchaser',
                            roles: 'Super User',
                            display: false,
                            breadcrumb: true,
                            title: 'Manage Purchaser | Purchasers | Customers | Admin'
                        }
                    },
                ]
            },
            {
                name: 'cardholders.dashboard',
                path: '/admin/cardholders',
                props: true,
                component: () => import('./views/admin/cardholders/dashboard.vue'),
                meta: {
                    label: 'menu.cardholders',
                    roles: 'Super User',
                    permissions: 'Manage customers',
                    display: true,
                    breadcrumb: true,
                    title: 'Cardholders | Customers | Admin'
                },
                children: [
                    {
                        name: 'manage.cardholder',
                        path: '/admin/cardholders/manage-cardholder/:guid',
                        props: true,
                        component: () => import('./views/admin/cardholders/cardholderDetails.vue'),
                        meta: {
                            label: 'menu.cardholder',
                            roles: 'Super User',
                            display: false,
                            breadcrumb: true,
                            title: 'Manage Cardholder | Cardholders | Customers | Admin'
                        }
                    },
                ]
            },
            {
                name: 'customers.enquiries',
                path: '/admin/enquiries',
                props:true,
                component: () => import('./views/admin/enquiries/enquiriesDashboard.vue'),
                meta: {
                    label: 'menu.enquiries',
                    roles: 'Super User',
                    permissions: 'Manage enquiries',
                    display:true,
                    breadcrumb: true,
                    title: 'Enquiries | Admin'
                },
                children: [
                    {
                        name: 'manage.enquiry',
                        path: '/admin/enquiry/manage/:guid',
                        props: true,
                        component: () => import('./views/admin/enquiries/enquiriesDetails.vue'),
                        meta: {
                            label: 'menu.enquiry',
                            roles: 'Super User',
                            display: false,
                            breadcrumb: true,
                            title: 'Manage Enquiry | Enquiries | Admin'
                        }
                    },
                ]
            },
        ]
    },
    {
        name: 'cards',
        path: '/admin/gift-cards',
        props:true,
        meta: {
            label: 'menu.gift_cards',
            header: true,
            display:true,
            roles: 'Super User',
            permissions: 'Manage cards',
            breadcrumb: false,
            title: 'Gift Cards | Admin'
        },
        children : [
            {
                name: 'cards.manage',
                path: '/admin/gift-cards/manage',
                props:true,
                component: () => import('./views/admin/cards/cardDashboard.vue'),
                meta: {
                    label: 'menu.cards',
                    roles: 'Super User',
                    permissions: 'Manage cards',
                    display:true,
                    breadcrumb: true,
                    title: 'Gift Cards | Admin'
                },
                children : [
                    {
                        name: 'manage.card',
                        path: '/admin/gift-cards/manage-card/:guid',
                        props:true,
                        component: () => import('./views/admin/cards/cardDetails.vue'),
                        meta: {
                            label: 'menu.card',
                            roles: 'Super User',
                            display:false,
                            breadcrumb: true,
                            title: 'Manage Gift Card | Gift Cards | Admin'
                        }
                    },
                ]
            },
            {
                name: 'cards.orders',
                path: '/admin/gift-cards/orders',
                props:true,
                component: () => import('./views/admin/orders/ordersDashboard.vue'),
                meta: {
                    label: 'menu.orders',
                    roles: 'Super User',
                    permissions: 'Manage orders',
                    display:true,
                    breadcrumb: true,
                    title: 'Orders | Gift Cards | Admin'
                },
                children : [
                    {
                        name: 'manage.order',
                        path: '/admin/gift-cards/manage-order/:guid',
                        props:true,
                        component: () => import('./views/admin/orders/ordersDetails.vue'),
                        meta: {
                            label: 'menu.order',
                            roles: 'Super User',
                            display:false,
                            breadcrumb: true,
                            title: 'Manage Order | Orders | Gift Cards | Admin'
                        }
                    },
                    {
                        name: 'create.order',
                        path: '/admin/gift-cards/create-order',
                        props:true,
                        component: () => import('./views/admin/orders/createOrder.vue'),
                        meta: {
                            label: 'menu.order',
                            roles: 'Super User',
                            display:false,
                            breadcrumb: true,
                            title: 'Create Order | Orders | Gift Cards | Admin'
                        }
                    },
                ]
            },
            {
                name: 'cards.fulfilment',
                path: '/admin/gift-cards/fulfilment',
                props: true,
                component: () => import('./views/admin/orders/fulfilmentDashboard.vue'),
                meta: {
                    label: 'menu.fulfilment',
                    roles: 'Super User',
                    permissions: 'Manage orders',
                    display: true,
                    breadcrumb: true,
                    title: 'Fulfilment | Gift Cards | Admin'
                }
            },
            {
                name: 'cards.promo',
                path: '/admin/gift-cards/promotion-codes',
                props:true,
                component: () => import('./views/admin/promotionalCodes/dashboard.vue'),
                meta: {
                    label: 'menu.promo_codes',
                    roles: 'Super User',
                    permissions: 'Manage orders',
                    display:true,
                    breadcrumb: true,
                    title: 'Promotional codes | Gift Cards | Admin'
                },
                children : [
                    {
                        name: 'manage.promo-codes',
                        path: '/admin/gift-cards/manage-promo/:guid',
                        props:true,
                        component: () => import('./views/admin/promotionalCodes/details.vue'),
                        meta: {
                            label: 'menu.promo_code',
                            roles: 'Super User',
                            display:false,
                            breadcrumb: true,
                            title: 'Manage promotional codes | Promotional codes | Gift Cards | Admin'
                        }
                    }
                ]
            },
            {
                name: 'cards.partners',
                path: '/admin/gift-cards/partners',
                props:true,
                component: () => import('./views/admin/cardPartners/dashboard.vue'),
                meta: {
                    label: 'menu.partners',
                    roles: 'Super User',
                    permissions: 'Manage partners',
                    display:true,
                    breadcrumb: true,
                    title: 'Card Partners | Gift Cards | Admin'
                },
                children : [
                    {
                        name: 'manage.partner',
                        path: '/admin/gift-cards/manage-partner/:guid',
                        props:true,
                        component: () => import('./views/admin/cardPartners/partnerDetails.vue'),
                        meta: {
                            label: 'menu.partner',
                            roles: 'Super User',
                            display:false,
                            breadcrumb: true,
                            title: 'Manage Card Partner | Card Partners | Gift Cards | Admin'
                        }
                    },
                ]
            }
        ]
    },
    {
        name: 'admin.users',
        path: '/admin/users',
        props:true,
        component: () => import('./views/admin/users/usersDashboard.vue'),
        meta: {
            label: 'menu.admin_users',
            header: true,
            roles: 'Super User',
            permissions: 'Manage users',
            display:true,
            breadcrumb: true,
            title: 'Users | Admin'
        },
    },
    {
        name: 'reports.dashboard',
        path: '/admin/reports',
        props:true,
        component: () => import('./views/reports/dashboard.vue'),
        meta: {
            label: 'menu.reports',
            header: true,
            roles: 'Super User',
            display:false,
            breadcrumb: false,
            title: 'Reports | Admin'
        },
    },
    {
        name: 'notifications',
        path: '/admin/notifications',
        props:true,
        component: () => import('./views/admin/notifications/notifications.vue'),
        meta: {
            label: 'menu.notifications',
            header: true,
            roles: 'Super User, Activities Provider',
            display:false,
            breadcrumb: false,
            title: 'Notifications | Admin'
        },
    },
    {
        name: 'profile.dashboard',
        path: '/profile',
        props:true,
        component: () => import('./views/profile/dashboard.vue'),
        meta: {
            label: 'menu.profile',
            display: false,
            roles: 'All',
            breadcrumb: true,
            title: 'My Profile'
        },
    },
    {
        name: 'api.xero.initiate',
        path: '/xero/initiate',
        props: false,
        component: () => import('./views/auth/xeroAuth.vue'),
        meta: {
            label: 'menu.xero',
            display: false,
            roles: 'All',
            breadcrumb: false,
        },
    },
    {
        name: 'docs',
        path: '/docs',
        meta: {
            label: '',
            display: false,
            roles: 'All',
            breadcrumb: false,
        },
    },
    {
        name: 'card.docs',
        path: '/card-docs',
        meta: {
            label: '',
            display: false,
            roles: 'All',
            breadcrumb: false,
        },
    },
]

const router = createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes
})
/*let isFirstTransition = true;

const MAX_TIME_TO_RETURN =new Date( Date.now() - 10000 * 60);

router.beforeEach((to, from, next) => {
    const lastRouteName = localStorage.getItem('LS_ROUTE_KEY');
    const lastActivityAt = localStorage.getItem('LS_LAST_ACTIVITY_AT_KEY');

    const hasBeenActiveRecently = Boolean(
        lastActivityAt && Date.now() - Number(lastActivityAt) < MAX_TIME_TO_RETURN
    );

    const shouldRedirect = Boolean(
        to.path === "/"
        && lastRouteName
        && isFirstTransition
        && hasBeenActiveRecently
    );

    if (shouldRedirect) next({ path: lastRouteName });
    else next();

    isFirstTransition = false;
});*/

const pageTitle = document.title

router.afterEach(to => {
    localStorage.setItem('LS_ROUTE_KEY', to.path);
    localStorage.setItem('LS_LAST_ACTIVITY_AT_KEY', Date.now());

    document.title = to.meta?.title ? `${to.meta.title} | ${pageTitle}` : pageTitle

    if (typeof gtag !== 'undefined') {
        setTimeout(() => {
            gtag('event', 'page_view', {
                page_title: document.title,
                page_location: location.href
            });
        }, 50)
    }
});


export default router;
