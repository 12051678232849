<template>
    <div v-if="errorMessages.length > 0" class="alert alert__error">
        <p v-for="(error, i) in errorMessages" :key="i">{{ error }}</p>
    </div>
    <template v-if="fileType === 'documents'">
        <input class="form--field--input form--field--file-input" :accept="accept" :multiple="!single" type="file"
               :name="name" :id="id" v-bind="attrs" @change="change">
        <label class="form--field--file-label">
            <template v-if="Array.isArray(uploadedFiles)" v-for="(file, idx) in uploadedFiles" :key="idx">
                <a class="" :href="file.url" target="_blank">{{ file.name ?? 'Unknown' }}</a>
            </template>
            <template v-else>
                {{ uploadedFiles.split('/').pop() }}
            </template>
            <template v-if="user.role.name === 'Super User'">
                <span v-if="modelValue == null">{{ $t('buttons.upload') }}</span>
                <span v-if="modelValue != null">{{ $t('buttons.replace') }}</span>
            </template>
        </label>
    </template>
    <template v-else-if="fileType === 'images'">
        <label>
            <input class="form--field--input form--field--file-input"
                   :accept="accept" :multiple="!single" type="file" :name="name" :id="id" v-bind="attrs"
                   @change.prevent="change"/>
            <div v-if="uploadedFiles instanceof Array" class="main-body--main-content--images-multi-cards--card">
                <div v-for="(file, idx) in uploadedFiles" :key="idx">
                    <div class="main-body--main-content--images-cards--card-delete_button"
                         type="button"
                         @click.prevent="remove(idx)" title="Remove file">X
                    </div>
                    <img :src="file.url" alt="Thumbnail preview">
                </div>
            </div>
             <div v-else class="main-body--main-content--images-cards--card-holder">
                <div class="main-body--main-content--images-cards--card">
                    <div class="main-body--main-content--images-cards--card-delete_button"
                         type="button"
                         @click.prevent="remove()" title="Remove file">X
                    </div>
                    <img v-if="uploadedFiles" :src="uploadedFiles" alt="Thumbnail preview">
                </div>
             </div>
            <div class="main-body--main-content-upload-button" v-if="user.role.name === 'Super User'">
                <div class="main-body--main-content-upload-button_box">
                    <div class="main-body--main-content-upload-button_box-label btn btn__border btn--container">
                        <template v-if="directory === 'brand_pictures'">
                        <span v-if="modelValue?.length === 0">{{ $t('buttons.upload')}}</span>
                        <span v-else>{{ $t('buttons.add') }}</span>
                        </template>
                        <template v-else>
                            <span v-if="modelValue == null">{{ $t('buttons.upload') }}</span>
                            <span v-if="modelValue != null">{{ $t('buttons.replace') }}</span>
                        </template>
                    </div>
                </div>
            </div>
        </label>
    </template>
    <template v-else>
        <label>
            <input class="form--field--input form--field--file-input"
                   :accept="accept" :multiple="!single" type="file" :name="name" :id="id" v-bind="attrs"
                   @change="change"/>
            <div class="main-body--main-content--images-cards--card-holder">
                <div class="main-body--main-content__video-container">
                    <div v-if="uploadedFiles instanceof Array" v-for="(file, idx) in uploadedFiles" :key="idx">
                        <video class="main-body--main-content__video-container-video" preload="auto" loop>
                            <source :src="file.url" type="video/mp4">
                        </video>
                        <div class="main-body--main-content__video-container-playpause"></div>
                    </div>
                    <div v-else-if="uploadedFiles">
                        <video class="main-body--main-content__video-container-video" preload="auto" loop>
                            <source :src="uploadedFiles" type="video/mp4">
                        </video>
                        <div class="main-body--main-content__video-container-playpause"></div>
                    </div>
                </div>
            </div>
            <div class="main-body--main-content-upload-button">
                <div class="main-body--main-content-upload-button_box">
                    <div class="main-body--main-content-upload-button_box-label btn btn__border btn--container"
                         v-if="user.role.name === 'Super User'">
                        <span v-if="modelValue == null">{{ $t('buttons.upload') }}</span>
                        <span v-if="modelValue != null">{{ $t('buttons.replace') }}</span>
                    </div>
                </div>
            </div>
        </label>
    </template>
</template>

<script>
import Form from '../../../src/form'

export default {
    inheritAttrs: false,
    props: {
        name: String,
        modelValue: [String, Number, Array],
        label: String,
        id: String,
        accept: {
            type: String,
            required: false
        },
        single: Boolean,
        props: {
            type: Object,
            required: false
        },
        directory: String,
        directoryName: String,
        fileType: String,
    },
    data() {
        return {
            form: new Form(this),
            value: '',
            uploadedFiles: [],
            user: [],
            errors:{},
        }
    },
    computed: {
        errorMessages() {
            return Object.values(this.errors).flat()
        },
        errorFields() {
            return Object.keys(this.errors)
        },
        attrs() {
            const attrs = Object.assign({}, this.$attrs)

            delete attrs.type
            delete attrs.multiple
            delete attrs.class
            delete attrs.name
            delete attrs.value
            delete attrs.id

            return attrs
        }
    },
    watch: {
        modelValue(value) {
            this.uploadedFiles = value;
        },
        uploadedFiles(files) {
            if (files instanceof Array) {
                    // console.log(files)
                if (files.length === 1 && this.directory !== 'brand_pictures') {
                    this.$emit('update:modelValue', files[0].url)
                } else {
                    this.$emit('update:modelValue', files)
                }
            }
        }
    },
    methods: {
        change(e) {
            const uploadedFiles = [...e.target.files]
            for (let i = 0; i < uploadedFiles.length; i++) {
                if(uploadedFiles[i].size > 5227880){
                    this.errors.fileSize = this.$t('general.file_upload_too_big')
                    this.uploadedFiles = [];
                    setTimeout(()=> {
                        this.errors = {};
                    },5000)
                } else {
                    const formData = new FormData();
                    formData.append('info', JSON.stringify({
                        'fileType': this.fileType,
                        'directory': this.directory,
                        'directory_name': this.directoryName
                    }));
                    formData.append('file', uploadedFiles[i]);

                    this.form.post('/portal/upload-files', formData).then(response => {
                        if (response.success) {
                            if (this.single === true) {
                                this.uploadedFiles = [];
                            }
                            // console.log(response.data)
                            this.uploadedFiles.push(response.data)
                        }
                    })
                }
            }
            console.log(this.uploadedFiles)
        },
        remove(file) {
            // if (!file.uploading) {
            // console.log(file)
            this.files = this.files.filter(otherFile => otherFile.id !== file)
            // this.uploadedFiles = this.uploadedFiles.filter(otherFile => otherFile.id !== file)
            if(this.uploadedFiles instanceof Array) {
                this.uploadedFiles.splice(file, 1)
            } else {
                this.uploadedFiles = [];
            }
            // }
        },
        dragover(event) {
            event.preventDefault()
            // Add some visual fluff to show the user can drop its files
        },
        dragleave(event) {
            // Clean up
        },
        drop(event) {
            this.onChange(event)
        },

    },
    created() {
        if (typeof this.files !== 'undefined') this.files = this.modelValue
        if (typeof this.files === 'undefined') this.files = []
        if (localStorage.getItem('user')) {
            this.user = JSON.parse(localStorage.getItem('user'))
        }
    }
}
</script>
