import { DateTime } from 'luxon'

export default {
    methods: {
        dateTime (date) {
            if (date) {
                if (date instanceof DateTime) {
                    date = date.toISO()
                }

                let dateObject = DateTime.fromISO(date)

                if (dateObject.invalid) {
                    dateObject = DateTime.fromFormat(date, 'dd/MM/yyyy')
                }

                return dateObject
            }

            return DateTime.now()
        }
    }
}
